import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/*
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        */}
      </header>
      <div class="header"></div>
      <div class="left"></div>
      <div class="resume-content">
        <h1>Djoanuelle Apostol</h1>
        <div id="education">
          <h2>Education</h2>
          <h3>Walla Walla University, College Place, WA - Bachelors of Science in Engineering Concentration in Computer Engineering, Minors in Business and Mathematics</h3>
          <h4>Graduated: January 2023, GPA: 3.27</h4>
        </div>
        <div id="projects">
          <h2>Projects</h2>
          <ul>
            <li>The Cloud Resume Project - Designed a Resume Website using React and used AWS resources to host the website.</li>
            <li>Capstone Senior Engineering Project - Designed and Implemented software for a Qi Wireless Power Transmitter.</li>
            <li>Terraform Project - Designed a static website using HTML and CSS and created a Terraform configuration to provision AWS infrastructure for the website.</li>
            <li>Jenkins Project - Created a CI/CD Pipeline using Jenkins to automate the building, testing, and deployment of a static website.</li>
            <li>Terraform/Jenkins Project - Created a CI/CD Pipeline using Jenkins which utilized Terraform to provision AWS infrastructure for a static website.</li>
            <li>Docker/Kubernetes Project - Created a simple node.js web application and used Docker to containerize the application and Kubernetes to deploy the application.</li>
            <li>Spotify React Project - Designed and developed a React Application that queries the Spotify API to create new playlists.</li>
            <li>Website Design System - Using HTML and CSS, designed a basic design system website. Shows colors, fonts, and text styles that could be used for website design.</li>
            <li>Excursion Project - Using HTML and CSS, created a web page which advertises a product called “Excursion”.</li>
            <li>Data Analysis using various algorithms: K-Nearest Neighbors, Naive Bayes, Decision Trees, Neural Networks, Regression.</li>
          </ul>
        </div>
        <div id="skills">
          <h2>Skills</h2>
          <h3>DevOps</h3>
          <ul>
            <li>Docker</li>
            <li>Terraform</li>
            <li>Kubernetes</li>
            <li>Jenkins</li>
          </ul>
          <h3>Scripting</h3>
          <ul>
            <li>Python</li>
            <li>Golang</li>
            <li>Ruby</li>
            <li>Bash</li>
            <li>Command line</li>
          </ul>
          <h3>Web Development</h3>
          <ul>
            <li>JavaScript</li>
            <li>HTML</li>
            <li>CSS</li>
            <li>React</li>
            <li>Redux</li>
          </ul>
          <h3>Database Technologies</h3>
          <ul>
            <li>SQL</li>
            <li>MySQL</li>
            <li>PostgreSQL </li>
            <li>MongoDB</li>
          </ul>
          <h3>Other Relevant Programming Languages and Skills</h3>
          <ul>
            <li>AWS</li>
            <li>Git, GitHub/GitLab</li>
            <li>Markdown</li>
            <li>C</li>
            <li>C++</li>
            <li>R</li>
            <li>Object Oriented Programming</li>
            <li>Data Structures</li>
          </ul>
        </div>
        <div id="certifications">
          <h2>Certifications</h2>
            <h3>AWS Certified Cloud Practitioner Certification (Number: XC7DHGM2SEF4153H)</h3>
        </div>
        <div id="experience">
          <h2>Experience</h2>
          <h3>Revivn, San Leandro, CA - Computer Repair Technician II</h3>
            <h4>May 8 2023 - Present</h4>
              <ul>
                <li>Assess conditions of incoming electronics</li>
                <li>Install and configure hardware,k software, and peripherals</li>
                <li>Technically inspect, classify, sort, disassemble and repair computer hardware</li>
                <li>Record configuration, repairs performed and cosmetic information in the Revivn inventory System</li>
              </ul>
          <h3>Walla Walla University, College Place, WA - Custodian</h3>
            <h4>September 2018 - February 2019</h4>
              <ul>
                <li>Monitored and maintained the cleanliness of campus buildings, offices, and bathrooms.</li>
              </ul>
          <h3>Hula Truck, Milpitas, CA - Cashier and Food Preparation</h3>
            <h4>August 2017 - September 2017, August 2018 - September 2018</h4>
              <ul>
                <li>Assisted customers in making food selections, and operated cash register to compute sales.</li>
                <li>Prepared food to be served to customers.</li>
                <li>Monitored and maintained cleanliness of the food truck.</li>
              </ul>
        </div>
        <div id="activities">
          <h2>Activities and Societies</h2>
          <h3>Walla Walla University, College Place, WA</h3>
            <ul>
              <li>Asian Pacific Islander President 2018 -2019: Organized and headed club activities, planned and coordinated club events, 
                delegated responsibilities to appropriate club officers, participated in club marketing and budgeting.</li>
              <li>Engineers Without Borders Local Project Team 2017-2018: Participated in research and design for a Mobile Shower Unit.</li>
            </ul>
        </div>
      </div>
      <div class="right"></div>
      <div class="footer">
        <h4 id="name">Djoanuelle Apostol</h4>
      </div>
    </div>
  );
}

export default App;
